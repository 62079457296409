<template>
  <ng-dialog :visible.sync="show" :bodyStyle="bodyStyle" @close="$emit('update:visible', false)">
    <template v-slot:title>
      <h1 class="ant-drawer-title m-bottom-xs" style="text-align: center;">开发票</h1>
    </template>

    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-form-item label="抬头类型">
        <a-radio-group v-model="ReceiptTitleType"
          
          @change="onChangeRecp"
          
          
        >
        <a-radio :value='1'>
          企业单位
        </a-radio>
        <a-radio :value='2'>
          个人/非企业单位
        </a-radio>
      </a-radio-group>
      </a-form-item>

      <a-form-item label="发票类型">
        <a-radio-group  v-model="ReceiptType" 
          v-bind:value ="ReceiptType"
          @change="onChangeRecpType"
          
          style="padding:0"
        >
        <a-radio :value='1'>
          纸质版增值税普通发票
        </a-radio>
        <a-radio :value='2' v-show="ReceiptTitleType==1">
          纸质版增值税专用发票
        </a-radio>
      </a-radio-group>
      </a-form-item>

      <a-form-item label="发票抬头">
        <a-input
          size="large"
          placeholder="请输入发票抬头"
          v-decorator="['name', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item> 

      <div v-if="ReceiptTitleType==1||ReceiptTitleType==0">
        <a-form-item label="纳税人识别号">
          <a-input
            size="large"
            :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_taxPayerCode')})"
            v-decorator="['taxer_number', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
          ></a-input>
        </a-form-item>
        <div v-if="ReceiptType==2">
          <a-form-item label="注册电话">
            <a-input
              size="large"
              :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_regPhone')})"
              v-decorator="['company_phone', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
            ></a-input>
          </a-form-item>

          <a-form-item label="注册地址">
            <a-input
              size="large"
              :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_regAddr')})"
              v-decorator="['company_address', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
            ></a-input>
          </a-form-item>

          <a-form-item label="开户银行">
            <a-input
              size="large"
              :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_bank')})"
              v-decorator="['bank', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
            ></a-input>
          </a-form-item>

          <a-form-item label="银行账号">
            <a-input
              size="large"
              :placeholder="$t('template.typeSth', {name: $t('userSet.rpt_bankAccount')})"
              v-decorator="['account', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
            ></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item label="联系人姓名">
        <a-input
          size="large"
          :placeholder="$t('userSet.addr_recevierPlaceholder')"
          v-decorator="['username', { rules: [$validate.required, $validate.letter_zh_number_char], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

       <a-form-item label="联系人电话">
        <a-input
          size="large"
          type="number"
          v-decorator="['phone', { rules: [$validate.phone,$validate.required], initialValue: '', trigger: 'blur' }]"
          :placeholder="$t('userSet.add_phonePlaceholder')"
        />
      </a-form-item>

      <a-form-item label="邮寄地址">
        <a-input
          size="large"
          :placeholder="$t('userSet.addr_receivAddrPlaceholder')"
          v-decorator="['address', { rules: [$validate.required], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item>

       <!-- <a-form-item label="联系邮箱">
        <a-input
          size="large"
          :placeholder="$t('userSet.addr_emialPlaceholder')"
          v-decorator="['email', { rules: [$validate.required, $validate.email], initialValue: '', validateTrigger: 'blur'  }]"
        ></a-input>
      </a-form-item> -->
    </a-form>

    <template slot="footer">
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk()"
        :loading="confirmLoading"
      >提交发票信息</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue';
  import {dialogMixins} from '@/mixins/index';
  import {ReceiptHeaderTypeOptions, ReceiptTypeOptions} from '@/config/options';

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};
// v-decorator="['company_phone', { rules: [$validate.required, $validate.phone], initialValue: '', validateTrigger: 'blur'  }]"
// :options="ReceiptTypeOptions"
// :options="ReceiptHeaderTypeOptions"
// v-decorator="['ReceiptTitleType', { rules: [$validate.required]}]"
// v-decorator="['invoice_type', { rules: [$validate.required]}]"

  export default {
    data() {
      return {
        show: false,
        confirmLoading: false,
        labelCol,
        wrapperCol,
        ReceiptHeaderTypeOptions,
        ReceiptTypeOptions,
        ReceiptTitleType: 1, //抬头类型
        ReceiptType: 1, //发票类型
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
      });
    },

    components: {
      NgDialog,
    },

    props: {
      data: Object,
      order_type: Number,
    },

    mixins: [dialogMixins],
    methods: {

      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            values.order_id = this.data.order_id
            this.applyReceipt(values);
          }
        });
      },

      applyReceipt(params) {
        this.confirmLoading = true;
        if(this.order_type == 2) {
          params.order_type = 3;
        } else {
          params.order_type = this.order_type;
        }
        params.title_type = this.ReceiptTitleType;
        params.invoice_type = this.ReceiptType;
        this.$axios.Order_receiptAdd(params).then(res => {
          const data = res.data;
          if (data && data.code == 0) {
            this.$message.success('申请成功');
            this.showCartDialog(false);
            this.$emit('success', data)
            this.$emit('loadlist', data)
          }
          this.confirmLoading = false;
        })
      },

      showCartDialog(bool) {
        this.$emit('update:visible', bool);
      },
      onChangeRecp(value) {
        if (value.target.value == 2){
          this.ReceiptType = 1;
          // console.log("====");
        }
        this.ReceiptTitleType = value.target.value;
        // console.log(this.ReceiptTitleType,typeof this.ReceiptTitleType);
        
      },
      onChangeRecpType(value) {
        // console.log("onChangeRecpType ",value.target.value);
        this.ReceiptType = value.target.value;
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep {
    .ant-radio-group {
      .ant-radio-wrapper {
        margin-right: 6px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
</style>
